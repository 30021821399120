import React, { FunctionComponent } from 'react';
import {
  DynamicForm,
  DynamicFormQuestionField,
  ErrorAlert,
  QueryLoaderWrapper
} from '@companion-professional/components';
import { SurveyFull } from '@companion-professional/dctypes';
import { VideoTypeContainer } from '../../components/VideoTypeContainer';
import { useSaveItemReviewAnswers } from '../../hooks/useSaveItemReviewAnswers';

interface ItemReviewProps {
  itemSurvey: SurveyFull<DynamicFormQuestionField>;
  itemId?: string;
  itemType?: string;
  markItemAsReviewed: (id: string) => void;
}

export const ItemReview: FunctionComponent<ItemReviewProps> = ({
  itemId,
  itemType,
  itemSurvey,
  markItemAsReviewed
}) => {
  const { saveItemReviewAnswers, error, isPending } = useSaveItemReviewAnswers();

  if (!itemType || !itemId) {
    return <ErrorAlert message="Item type or item ID not provided" />;
  }

  return (
    <QueryLoaderWrapper isPending={isPending} error={error}>
      <div className="flex flex-col items-center gap-y-4 rounded-lg border border-gray-400 bg-widget p-4 text-on-widget">
        <div className="aspect-video w-[500px]">
          {itemType === 'video' ? (
            <VideoTypeContainer videoItemId={itemId} playbackRates={[0.125, 0.25, 0.5, 1, 2, 4]} />
          ) : (
            <ErrorAlert message={`Item type "${itemType}"" not supported`} />
          )}
        </div>
        <div>
          <DynamicForm
            fields={itemSurvey?.questions || []}
            showNumbers
            disabled={isPending}
            onSubmit={async (data) => {
              try {
                await saveItemReviewAnswers({
                  surveyId: itemSurvey.survey_id,
                  version: itemSurvey.version,
                  itemId,
                  answers: data
                });
                markItemAsReviewed(itemId);
              } catch (e) {
                // The hook should handle the error, also logging here for good measure.
                console.error(e);
              }
            }}
          />
        </div>
      </div>
    </QueryLoaderWrapper>
  );
};
