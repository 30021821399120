import React, { FunctionComponent } from 'react';
import { DebugTableRow } from './DebugTableRow';
import { cn } from '@companion-professional/webutils';

interface DebugObjectTableBodyProps {
  // isTopLevel is a boolean that indicates if the table body is the top level of the table (currently this is just used
  // to determine if the table body should have a border or not).
  isTopLevel?: boolean;

  // objectToDisplay is the object that's details will be displayed in the table.
  objectToDisplay: { [key: string]: any };
}

// DebugObjectTableBody is used to display the body of a table that displays a nested object.
export const DebugObjectTableBody: FunctionComponent<DebugObjectTableBodyProps> = ({
  objectToDisplay,
  isTopLevel = false
}) => {
  return (
    <tbody
      className={cn({
        'border border-gray-400': !isTopLevel
      })}
    >
      {Object.entries(objectToDisplay).map(([key, value], index) => (
        <DebugTableRow key={key} index={index} entry={[key, value]} />
      ))}
    </tbody>
  );
};
