import React, { FunctionComponent } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { cn } from '@companion-professional/webutils';
import { AuthRoleType } from '@companion-professional/dctypes';
import { useSetMobileMenuOpen } from '../../state/layout';
import { useRoles } from '../../state/auth';

interface SideNavLinkProps extends LinkProps {
  // restrictToRoles is an array of roles that the user must have at least one of for the link to be displayed. If
  // restrictToRoles is not provided, the link will be displayed for all users.
  restrictToRoles?: AuthRoleType[];
}

// SideNavLink is a stylized Link component that is used in the SideNav component.
export const SideNavLink: FunctionComponent<SideNavLinkProps> = ({
  restrictToRoles,
  children,
  className,
  onClick,
  ...props
}) => {
  const roles = useRoles();
  const location = useLocation();
  const isActive =
    (location.pathname === '/' && props.to === '/') ||
    (props.to !== '/' && location.pathname.startsWith(props?.to as string));
  const setMobileMenuOpen = useSetMobileMenuOpen();

  if (restrictToRoles && !roles.some((role) => restrictToRoles.includes(role))) {
    return null;
  }

  return (
    <Link
      className={cn(
        [
          'flex',
          'flex-row',
          'space-x-2',
          'items-center',
          'rounded',
          'mb-2',
          'px-4',
          'py-2.5',
          'font-medium',
          'transition',
          'duration-200',
          {
            'bg-on-primary': isActive,
            'text-primary': isActive,
            'font-bold': isActive,
            'hover:bg-secondary/90': !isActive,
            'hover:text-widget': !isActive
          }
        ],
        className
      )}
      onClick={(e) => {
        setMobileMenuOpen(false);
        onClick?.(e);
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
