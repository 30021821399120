// companionProColors are the named colors used in the Companion branding.
import { BrandingColors } from './branding-types';

const companionProColors = {
  leafyGreen: '#26575C',
  leafyLightGreen: '#488E94',
  beagleBeige: '#F2E9D8',
  basset: '#E6D5B7',
  bone: '#F8F3EB',
  collieCharcoal: '#3E3E3E',
  rhodesianRed: '#D28060',
  biscuit: '#B15F3F',
  setterSunshine: '#E6B455',
  pureWhite: '#FFFFFF'
};

// This is the default color scheme for Companion Pro.
export const companionProTheme: BrandingColors = {
  primary: companionProColors.leafyGreen,
  onPrimary: companionProColors.pureWhite,
  secondary: companionProColors.rhodesianRed,
  onSecondary: companionProColors.pureWhite,
  destructive: companionProColors.biscuit,
  onDestructive: companionProColors.pureWhite,
  accent: companionProColors.setterSunshine,
  onAccent: companionProColors.collieCharcoal,
  widget: companionProColors.pureWhite,
  onWidget: companionProColors.collieCharcoal,
  background: companionProColors.bone,
  onBackground: companionProColors.collieCharcoal
};

// TODO: These colors are not final and are placeholders for now.  If we want another color scheme, we can add it here.
export const companionProDarkTheme: BrandingColors = {
  primary: companionProColors.pureWhite,
  onPrimary: companionProColors.leafyGreen,
  secondary: companionProColors.pureWhite,
  onSecondary: companionProColors.rhodesianRed,
  destructive: companionProColors.biscuit,
  onDestructive: companionProColors.pureWhite,
  accent: companionProColors.setterSunshine,
  onAccent: companionProColors.collieCharcoal,
  widget: companionProColors.collieCharcoal,
  onWidget: companionProColors.pureWhite,
  background: companionProColors.collieCharcoal,
  onBackground: companionProColors.bone
};
