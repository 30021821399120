import React, { FunctionComponent, useEffect } from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { cn } from '@companion-professional/webutils';
import { Button } from '../Button';
import { Calendar } from '../Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';

export * from './dateRangeFunctions';

interface DateRangePickerProps {
  // onValueChange is a function that is called when the date range is changed.  It is passed the new date range as an
  // argument.
  onValueChange?: (value?: DateRange) => void;

  // value is an object with two keys, from and to, that represent the current values of the range. Helper functions
  // are provided in dateRangeFunctions.ts (to get ranges like: last week, last month, etc.)
  value?: DateRange;

  // dateFormat is a string that represents the format of the date to be displayed.  This should be in the date-fns
  // format function https://date-fns.org/v3.6.0/docs/format
  dateFormat?: string;

  // className will be applied to the root element of the component (the <div/> that wraps everything)
  className?: string;
}

// DateRangePicker is a component that allows the user to select a range of dates.  It uses the react-day-picker
// library (via the Calendar component) to provide a calendar interface for selecting the dates.
// TODO: I don't entirely like the user interface of this component (or more specifically the underlining
//  react-day-picker library).  Initially selecting the range works as I would expect, but adjusting the range I think
//  is a bit clunky.  I would like to look into either improving it through the (hopefully) the options in the
//  react-day-picker library, look into another base library, or potentially write our own.
export const DateRangePicker: FunctionComponent<DateRangePickerProps> = ({
  value,
  dateFormat = 'LLL dd, y',
  className,
  onValueChange = () => {}
}) => {
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>();

  useEffect(() => {
    setDateRange(value);
  }, [value]);

  useEffect(() => {
    onValueChange(dateRange);
  }, [dateRange]);

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="outline"
            size="small"
            className={cn(
              'text-on-widget w-[220px] justify-start bg-widget text-left font-normal',
              !dateRange && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateRange?.from ? (
              dateRange.to ? (
                <>
                  {format(dateRange.from, dateFormat)} - {format(dateRange.to, dateFormat)}
                </>
              ) : (
                format(dateRange.from, dateFormat)
              )
            ) : (
              <span>Select date range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={setDateRange}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
