import React, { FunctionComponent, useState } from 'react';
import { Copy } from 'lucide-react';
import { cn } from '@companion-professional/webutils';
import { toast } from '../Toast';
import { DebugObjectTableBody } from './DebugObjectTableBody';

interface DebugObjectValueProps {
  // entry is a tuple that contains the key and value of the object that will be displayed.
  entry: [string, unknown];
}

// DebugObjectValue displays the value of an object in a table format.  If the value is a string, it will be displayed
// in a div with a copy button.  If the value is an array or object, it will call DebugObjectTableBody to display the
// nested object.
export const DebugObjectValue: FunctionComponent<DebugObjectValueProps> = ({ entry: [key, value] }) => {
  // Hovering is only used for the copy button
  const [hovering, setHovering] = useState(false);

  if (typeof value === 'string') {
    return (
      <div className="flex flex-row items-center justify-between">
        <div
          className={cn('px-2 py-1', {
            'bg-secondary text-on-secondary': hovering
          })}
        >
          {value}
        </div>
        <Copy
          className="size-3 cursor-pointer hover:text-secondary"
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(value);
              toast(`Copied value for ${key} to clipboard`, {
                description: value
              });
            } catch (err) {
              console.error(`Could not copy text "${value}" to clipboard`, err);
            }
          }}
        />
      </div>
    );
  }

  if (typeof value === 'number') {
    return <span>{value}</span>;
  }

  if (Array.isArray(value)) {
    return (
      <table className="my-2 w-full">
        {value.map((obj, index) => (
          <DebugObjectTableBody objectToDisplay={obj} key={`${key}-${index}`} />
        ))}
      </table>
    );
  }

  if (typeof value === 'object' && value !== null) {
    return (
      <table className="my-2 w-full">
        <DebugObjectTableBody objectToDisplay={value} />
      </table>
    );
  }

  return <span className="text-destructive">Value parser not implemented</span>;
};
