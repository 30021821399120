import { useQuery } from '@tanstack/react-query';
import { DataSummaryStats } from '@companion-professional/dctypes';
import { getClinicMonthSummaryReport } from '../lib/api';

type Summary = {
  numVideos: number;
  numReviewed: number;
  numUsable: number;
};

export type ClinicSummary = {
  clinicName: string;
  totals: Summary;
  months: Record<string, Summary>;
};

// useClinicMonthSummaryReport is a hook that fetches the clinic summary by month report data and then processes it to
// return a list of clinic summaries (an array of ClinicSummary).
export const useClinicMonthSummaryReport = () => {
  const { isPending, error, data } = useQuery<DataSummaryStats[]>({
    queryKey: ['clinic-month-summary-report'],
    queryFn: () => getClinicMonthSummaryReport()
  });

  let summaryStats: ClinicSummary[] = [];
  if (data) {
    let clinicNames = new Set<string>(data.map((entry) => entry.clinic_name).sort());
    summaryStats = [...clinicNames].map((clinicName) => {
      let clinicData = data.filter((entry) => entry.clinic_name === clinicName);
      let clinicSummary: ClinicSummary = {
        clinicName: clinicName,
        totals: {
          numVideos: clinicData.reduce((acc, entry) => acc + entry.num_videos, 0),
          numReviewed: clinicData.reduce((acc, entry) => acc + entry.num_reviewed, 0),
          numUsable: clinicData.reduce((acc, entry) => acc + entry.num_usable, 0)
        },
        months: {}
      };

      clinicData.forEach((entry) => {
        clinicSummary.months[`${entry.year}-${entry.month.padStart(2, '0')}`] = {
          numVideos: entry.num_videos,
          numReviewed: entry.num_reviewed,
          numUsable: entry.num_usable
        };
      });

      return clinicSummary;
    });
  }

  return {
    isPending,
    error,
    summaryStats
  };
};
