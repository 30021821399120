import React, { FunctionComponent, useState } from 'react';
import { ChevronsLeftRightEllipsis } from 'lucide-react';
import { cn } from '@companion-professional/webutils';
import {
  buttonVariants,
  Dialog,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DebugObjectDisplay
} from '@companion-professional/components';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { useRoles } from '../../state/auth';

interface CheckupAdvancedInfoDialogProps {
  checkup: CheckupRequestInfo;
}

export const CheckupAdvancedInfoDialog: FunctionComponent<CheckupAdvancedInfoDialogProps> = ({ checkup }) => {
  const [open, setOpen] = useState(false);
  const roles = useRoles();

  // Only show the advanced info dialog button to global admins.
  if (!roles.includes('global_admin')) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={(o) => setOpen(o)}>
      <div
        className={cn('cursor-pointer', buttonVariants({ variant: 'primary', size: 'icon' }))}
        onClick={() => setOpen(true)}
      >
        <ChevronsLeftRightEllipsis />
      </div>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Debug Information</DialogTitle>
          <DialogDescription>
            This is all the data that has been returned to the frontend for this checkup
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="font-semibold">Checkup</div>
          <DebugObjectDisplay objectToDisplay={checkup} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
