import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@companion-professional/webutils';

const badgeVariants = cva(
  [
    'inline-flex',
    'items-center',
    'rounded-full',
    'border',
    'px-2.5',
    'py-0.5',
    'text-xs',
    'font-semibold',
    'transition-colors',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-ring',
    'focus:ring-offset-2'
  ],
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-on-primary hover:brightness-125',
        secondary: 'border-transparent bg-secondary text-on-secondary hover:brightness-125',
        destructive: 'border-transparent bg-destructive text-on-destructive hover:brightness-125',
        outline: 'text-foreground'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

// Badge is a component that displays a small amount of information.  It's a little like a small button, but doesn't
// necessarily have any click functionality.
function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
