import React, { FunctionComponent } from 'react';
import { Table } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Section } from '../../components/Section';

// ReportMenu is a component that displays a list of reports that the user can view.
export const ReportMenu: FunctionComponent = () => {
  return (
    <Section>
      <div className="my-6">
        <Link to="./reviewed-summary-by-clinic">
          <div className="flex cursor-pointer flex-row gap-2">
            <Table />
            <span className="text-blue-700 underline hover:text-blue-900">Reviewed Summary by Clinic and Month</span>
          </div>
        </Link>
      </div>
    </Section>
  );
};
