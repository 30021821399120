import React, { FunctionComponent } from 'react';

interface CellProps {
  children: React.ReactNode;
}

export const HeaderCell: FunctionComponent<CellProps> = ({ children }) => (
  <th className="px-1 pb-1 pt-2 text-left">{children}</th>
);

export const ClinicTopCell: FunctionComponent<CellProps> = ({ children }) => (
  <td className="p-1 text-left font-semibold text-gray-900">{children}</td>
);

export const DateMonthCell: FunctionComponent<CellProps> = ({ children }) => (
  <td className="p-1 pl-4 text-left font-semibold">{children}</td>
);

export const DateSummaryCell: FunctionComponent<CellProps> = ({ children }) => (
  <td className="p-1 text-left">{children}</td>
);
