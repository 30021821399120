import React, { FunctionComponent, useEffect, useState } from 'react';
import { Label } from '../Label';
import { DiagnosisOption, DiagnosisValue } from '../ImageDiagnosis';
import { cn } from '@companion-professional/webutils';

interface PainSelectorProps {
  name: string;
  label: string;
  description?: string;
  options: DiagnosisOption[];
  value?: DiagnosisValue;
  onChange?: (value?: DiagnosisValue) => void;
  disabled?: boolean;
}

export const PainSelector: FunctionComponent<PainSelectorProps> = ({
  label,
  description,
  options,
  onChange = () => {},
  value,
  disabled = false
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
  }, [value]);

  return (
    <div className="mb-2 flex w-full max-w-xl flex-col">
      <Label description={description}>{label}</Label>
      <div className="mt-2 flex flex-row gap-2">
        {options.map((option) => (
          <div
            key={option.label}
            className={cn('border-on-widget flex-1 cursor-pointer rounded-md border bg-widget py-3 text-center', {
              'font-semibold': selectedValue === option.value,
              'hover:opacity-70': !disabled
            })}
            style={selectedValue === option.value ? { backgroundColor: option.color } : {}}
            onClick={() => {
              if (disabled) {
                return;
              }
              setSelectedValue(option.value);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
      <div className="text-on-widget mt-1 flex flex-row justify-between text-sm italic">
        <div>less pain</div>
        <div>more pain</div>
      </div>
    </div>
  );
};
