import React, { FunctionComponent } from 'react';

interface FieldNumberProps {
  number: number;
  required: boolean;
}

// FieldNumber displays a number with an optional asterisk to indicate that the field is required.
export const FieldNumber: FunctionComponent<FieldNumberProps> = ({ number, required }) => {
  return (
    <div className="flex flex-row gap-1 pr-2">
      {required ? <div className="text-sm font-semibold leading-none text-on-widget">*</div> : null}
      <div className="font-semibold leading-none text-secondary">{number}.</div>
    </div>
  );
};
