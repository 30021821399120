import React, { FunctionComponent, useEffect, useState } from 'react';
import { InputWithLabel, QueryLoaderWrapper } from '@companion-professional/components';
import { useCheckupUrl } from '../../../hooks/useCheckupUrl';
import { PimsSettingsProps } from './PimsSettingProps';
import { LanderUrl } from './LanderUrl';

// Fake displays the Fake PIMS settings.  Currently, this just includes a form to create a Pet Checkup URL (with the
// clinic slug).  Filling in the patient ids will add them to the URL.
export const Fake: FunctionComponent<PimsSettingsProps> = ({ currentClinic }) => {
  const [landerUrl, setLanderUrl] = useState<string | null>(null);
  const [patientIds, setPatientIds] = useState('');
  const { getCheckupLanderUrl, isPending, error } = useCheckupUrl();

  useEffect(() => {
    if (currentClinic?.slug) {
      setLanderUrl(`${getCheckupLanderUrl(currentClinic.slug)}?p=${patientIds}`);
    }
  }, [currentClinic, getCheckupLanderUrl, patientIds]);

  return (
    <div>
      <div className="text-xl font-bold">Fake PIMS</div>
      <div className="flex flex-row items-center gap-6 py-4">
        <div className="max-w-[500px]">
          <p className="font-bold">Checkup Template URL</p>
          <p className="text-sm opacity-70">
            This creates a link that can be used to look up a pet's information. NOTE: this is a demo system and does
            not actually connect to an actual clinic's system.
          </p>
        </div>
        <div className="w-full min-w-72">
          <QueryLoaderWrapper isPending={isPending} error={error} size="small">
            <InputWithLabel
              value={patientIds}
              label="Patient Ids"
              onChange={(e) => setPatientIds(e.target.value)}
              placeholder="Enter patient ids separated by commas (e.g. fake-pet-001)"
            />
            {landerUrl ? (
              <LanderUrl landerUrl={landerUrl} clipboardCopySuccessMessage="Fake PIMS URL copied to clipboard" />
            ) : null}
          </QueryLoaderWrapper>
        </div>
      </div>
    </div>
  );
};
