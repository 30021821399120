import React, { FunctionComponent } from 'react';
import { cn } from '@companion-professional/webutils';

interface SectionProps {
  // title is the title of the section.
  title?: string;

  // children are the components that are displayed in the section.
  children?: React.ReactNode;

  // className can be used to add additional classes to the section component.
  className?: string;
}

export const Section: FunctionComponent<SectionProps> = ({ title, children, className }) => {
  return (
    <div className={cn('border-widget-foreground/20 border bg-widget px-4 py-2', className)}>
      {title ? <h1 className="mb-6 text-xl font-semibold">{title}</h1> : null}
      {children}
    </div>
  );
};
