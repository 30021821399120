import React, { useCallback } from 'react';
import { cn } from '@companion-professional/webutils';
import { Table } from '@tanstack/react-table';
import { BulkOperationSelectingTitle } from './BulkOperationSelectingTitle';
import { BulkOperation, BulkOptionMenuItem } from './dataTableTypes';
import { BulkOperationTitleAndMenu } from './BulkOperationTitleAndMenu';
import { BulkOperationFooter } from './BulkOperationFooter';

interface BulkOperationWrapperProps<TData> {
  // table is the table instance from react-table
  table: Table<TData>;

  // title is the title of the table that's displayed at the top of the header
  title?: string;

  // children is the content of the DataTable
  children?: React.ReactNode;

  // selectingRows is a boolean that indicates whether rows are being selected NOTE: this state is managed by the parent
  // component.
  selectingRows?: boolean;

  // setSelectingRows is a function that sets the selectingRows state.  NOTE: this state is managed by the parent
  // component.
  setSelectingRows?: (isSelectingRows: boolean) => void;

  // enableBulkOperationHeader is a boolean that indicates whether the bulk operation header should be displayed
  enableBulkOperationHeader?: boolean;

  // bulkOptionMenuItems is an array of bulk option menu items that are displayed in the dropdown menu in the header
  // (e.g. "Add Health Data", "Select Tasks", etc.)
  bulkOptionMenuItems?: BulkOptionMenuItem<TData>[];

  // getRowTypeName is a function that returns a string that describes the type of row that's being selected (e.g.
  // "Checkup", "Task", etc.).  This is a function so that it can be customized based on the number of rows that are
  // selected (so "Checkups" for multiple rows, "Checkup" for a single row).
  getRowTypeName?: (numRows: number) => string;
}

// BulkOperationWrapper is a component that wraps around core of the DataTable and provides a header and footer with
// features that allow for bulk operations on table rows.  If enableBulkOperationHeader is set to true, the header
// will display a title and a dropdown menu with high level bulk options (i.e. "Add Survey Data" or "Archive").  These
// bulk options (if selected) manifest as a checkbox at the start of each row that allows for the selecting of multiple
// rows.  Each bulk options has an array of operations that can be performed on the selected rows; in most cases this
// will be a single operation (i.e. "Add Health Data"), but it could have multiple operations if the bulk option is
// something more vague like "Select Tasks" (in this case, the operations might be something like "Mark as Complete"
// and "Mark as Incomplete"). These operations show up as buttons in the footer when selectingRows is true.  The header
// also has a cancel button (X) that cancels the selection of rows.
export function BulkOperationWrapper<TData>({
  table,
  title = '',
  children,
  enableBulkOperationHeader = false,
  selectingRows = false,
  setSelectingRows = () => {},
  bulkOptionMenuItems = [],
  getRowTypeName = (numRows) => (numRows === 1 ? 'row' : 'rows')
}: BulkOperationWrapperProps<TData>) {
  const [currentBulkOperations, setCurrentBulkOperations] = React.useState<BulkOperation<TData>[]>([]);

  // If the bulk operation header is not enabled, just return the title and children
  if (!enableBulkOperationHeader) {
    return (
      <div>
        <div className="m-2 text-xl font-semibold">{title}</div>
        <div>{children}</div>
      </div>
    );
  }

  const cancelSelection = useCallback(() => {
    table.toggleAllRowsSelected(false);
    setSelectingRows(false);
  }, [setSelectingRows, table]);

  return (
    <div
      className={cn('flex flex-col p-2', {
        'rounded-md bg-primary text-on-primary': selectingRows
      })}
    >
      <div className="mb-2 flex flex-row items-center justify-between">
        {selectingRows ? (
          <BulkOperationSelectingTitle title={title} onCancel={cancelSelection} />
        ) : (
          <BulkOperationTitleAndMenu
            title={title}
            bulkOptionMenuItems={bulkOptionMenuItems}
            setSelectingRows={setSelectingRows}
            setCurrentBulkOperations={setCurrentBulkOperations}
          />
        )}
      </div>
      {children}
      {selectingRows ? (
        <BulkOperationFooter table={table} getRowTypeName={getRowTypeName} operations={currentBulkOperations} />
      ) : null}
    </div>
  );
}
