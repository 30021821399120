import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { SurveyFull } from '@companion-professional/dctypes';
import { Button, DynamicFormQuestionField, ErrorAlert } from '@companion-professional/components';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../components/Page';
import { CheckSquare, ArrowLeft, ArrowRight } from 'lucide-react';
import { ItemReview } from './ItemReview';
import { useQueryClient } from '@tanstack/react-query';

interface ItemReviewSequenceWrapperProps {
  itemSurvey: SurveyFull<DynamicFormQuestionField>;
  checkupSurvey?: SurveyFull<DynamicFormQuestionField>;
  itemsToReviewIds?: string[];
}

export const ItemReviewSequenceWrapper: FunctionComponent<ItemReviewSequenceWrapperProps> = ({
  itemSurvey,
  checkupSurvey,
  itemsToReviewIds = []
}) => {
  const navigate = useNavigate();
  const { itemId, itemType } = useParams();

  if (!itemType || !itemId) {
    return <ErrorAlert message="Item type or item ID not provided" />;
  }

  // Keep a list of reviewed items
  const [itemsReviewed, setItemsReviewed] = useState<string[]>([]);
  const [reviewIdSequence, setReviewIdSequence] = useState<string[]>([]);
  const [nextItemIndex, setNextItemIndex] = useState(0);
  const queryClient = useQueryClient();

  const reviewPath = `/reviews/items-to-review/${checkupSurvey?.survey_id}/${itemSurvey?.survey_id}`;

  useEffect(() => {
    // This is here to prevent some strange behavior that could happen if the user reloads the page after submitting
    // the answers and the item is no longer in the list of items to review.
    if (!itemsToReviewIds.includes(itemId)) {
      navigate(reviewPath);
    }
  }, []);

  useEffect(() => {
    // Set the items to review
    setReviewIdSequence(itemsToReviewIds);
  }, [itemsToReviewIds]);

  useEffect(() => {
    // Set the next item index
    setNextItemIndex(reviewIdSequence.indexOf(itemId) + 1);
  }, [itemId, reviewIdSequence]);

  const isItemReviewed = useCallback((id: string) => itemsReviewed.includes(id), [itemsReviewed]);
  const markItemAsReviewed = useCallback((id: string) => setItemsReviewed([...itemsReviewed, id]), [itemsReviewed]);

  return (
    <Page
      title={`${checkupSurvey?.name} :: ${itemSurvey?.name} -- Item Review (${nextItemIndex} of ${itemsToReviewIds.length})`}
      wrapWithSection={false}
    >
      {isItemReviewed(itemId) ? (
        <div className="flex min-h-96 flex-row items-center justify-center">
          <div className="flex flex-row items-center gap-4 rounded-lg bg-primary px-6 py-4 text-on-primary">
            <CheckSquare size={32} />
            <div className="flex flex-col">
              <div className="text-xl font-semibold">Item Reviewed</div>
              <div className="text-sm">
                <span className="font-semibold">{itemSurvey.title || itemSurvey.name}</span> answers have been submitted
                for this item.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-10 mt-4">
          <ItemReview
            itemSurvey={itemSurvey}
            itemId={itemId}
            itemType={itemType}
            markItemAsReviewed={markItemAsReviewed}
          />
        </div>
      )}
      <div className="flex w-full flex-1 flex-row justify-between">
        <Button
          size="small"
          variant="outline"
          onClick={async () => {
            // We need to manually invalidate the query cache here so that a fresh list of items to review is fetched
            // when the user navigates back to the items to review page.
            await queryClient.invalidateQueries({
              queryKey: ['items-to-review', checkupSurvey?.survey_id, itemSurvey?.survey_id]
            });

            navigate(reviewPath);
          }}
          className="bg-widget"
        >
          <ArrowLeft />
          Back to Items
        </Button>
        <Button
          size="small"
          variant="outline"
          disabled={nextItemIndex >= itemsToReviewIds.length}
          onClick={() => {
            navigate(`${reviewPath}/${itemType}/${itemsToReviewIds[nextItemIndex]}`);
          }}
          className="bg-widget"
        >
          Next Item
          <ArrowRight />
        </Button>
      </div>
    </Page>
  );
};
