import React, { FunctionComponent, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Sparkles } from 'lucide-react';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QueryLoaderWrapper, toast } from '@companion-professional/components';
import { AuthRoles } from '@companion-professional/dctypes';
import { AppLayout } from './components/AppLayout';
import {
  Dashboard,
  PageNotFound,
  PetParents,
  Pets,
  Settings,
  Profile,
  Checkup,
  GenerateError,
  MaintenanceMode,
  Reports
} from './pages';
import { FeatureFlags } from './types/featureFlags';
import { Reviews } from './pages/Reviews';
import { RoleProtectedRoutes } from './components/RoleProtectedRoutes';
import { useCurrentClinic } from './hooks/useCurrentClinic';
import { useResetSwitchClinicId, useSwitchClinicId } from './state/auth';
import { SwitchClinicDialog } from './components/SwitchClinicDialog';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// This flag is used to determine if the site was in maintenance mode on the last render. If it was, then we can show a
// toast to let the user know that the site has been updated.  This is in the module scope so that changing it won't cause
// a re-render.
let wasInMaintenanceMode = false;

// These are all the routes that are available to a logged-in user.
export const LoggedInRoutes: FunctionComponent = () => {
  const location = useLocation();
  const flags = useFlags<FeatureFlags>();
  const switchClinicId = useSwitchClinicId();
  const resetSwitchClinicId = useResetSwitchClinicId();
  const { clinic, isPending, error } = useCurrentClinic();

  useEffect(() => {
    if (wasInMaintenanceMode && !flags?.siteInMaintenanceMode) {
      // When the flag switches from true to false, show a toast to let the user know that the site has been updated,
      // and they should refresh their browser.
      wasInMaintenanceMode = false;
      toast('New Site Version', {
        description: `This app was recently updated. Please refresh your browser to get the latest version.`,
        icon: <Sparkles />,
        duration: Infinity,
        position: 'top-center'
      });
    }
  }, [flags?.siteInMaintenanceMode]);

  if (flags?.siteInMaintenanceMode) {
    // If the site is in maintenance mode, then we don't want to show the normal app.  Instead, we show the
    // MaintenanceMode page.
    wasInMaintenanceMode = true;
    return <MaintenanceMode />;
  }

  return (
    <AppLayout currentClinic={clinic}>
      <SwitchClinicDialog
        switchToClinicId={switchClinicId}
        returnUrl={location.pathname}
        open={switchClinicId.length > 0}
        onOpenChange={(open) => {
          if (!open) {
            // If the dialog is closed, then we want to clear the switch clinic id.
            resetSwitchClinicId();
          }
        }}
      />
      <QueryLoaderWrapper isPending={isPending} error={error}>
        <SentryRoutes>
          <Route index element={<Dashboard />} />
          <Route path="/pet-parents/*" element={<PetParents />} />
          <Route path="/pets/*" element={<Pets />} />
          <Route path="/checkup/:checkupId" element={<Checkup />} />
          <Route path="/settings" element={<Settings currentClinic={clinic} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/generate-error" element={<GenerateError />} />
          <Route element={<RoleProtectedRoutes allowRoles={[AuthRoles.global_admin]} redirectPath="/" />}>
            <Route path="/reports/*" element={<Reports />} />
            <Route path="/reviews/*" element={<Reviews />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </SentryRoutes>
      </QueryLoaderWrapper>
    </AppLayout>
  );
};
