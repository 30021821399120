import { create } from 'zustand';
import { BrandingColors } from './branding-types';
import { companionProTheme } from './companionPro';

export type BrandingState = {
  colors: BrandingColors;
  setColors: (colors: BrandingColors) => void;
};

const useBrandingStateStore = create<BrandingState>((set) => ({
  // TODO: replace with a more neutral set of default colors
  colors: companionProTheme,
  setColors: (colors) => set({ colors })
}));

export const useColors = () => useBrandingStateStore((state) => state.colors);
export const useSetColors = () => useBrandingStateStore((state) => state.setColors);
