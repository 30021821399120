import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from '@companion-professional/webutils';
import { Button, QueryLoaderWrapper } from '@companion-professional/components';
import { useClinicMonthSummaryReport } from '../../../hooks/useClinicMonthSummaryReport';
import { Section } from '../../../components/Section';
import { ClinicTopCell, HeaderCell } from '../TableComponents';
import { ClinicSummaryRows } from './ClinicSummaryRows';

// ReviewedSummaryByClinic is a component that displays a summary of all the videos that have been reviewed by clinic
// by month.  It displays the total number of videos, the total number of videos reviewed, and the total number of
// videos that are usable (i.e. marked as usable using the "Reviews"/"Review Surveys" workflow).
export const ReviewedSummaryByClinic: FunctionComponent = () => {
  const navigate = useNavigate();
  const { isPending, error, summaryStats } = useClinicMonthSummaryReport();

  return (
    <div>
      <QueryLoaderWrapper isPending={isPending} error={error}>
        <Section title="Reviewed Summary by Clinic and Month">
          <table className="mb-2 w-full table-auto text-left text-sm text-gray-600">
            <thead className="bg-primary text-xs font-semibold uppercase text-on-primary">
              <tr>
                <HeaderCell>Clinic</HeaderCell>
                <HeaderCell>Total Videos</HeaderCell>
                <HeaderCell>Total Number Reviewed</HeaderCell>
                <HeaderCell>Total Usable</HeaderCell>
              </tr>
            </thead>

            {summaryStats.map((clinicSummary, i) => (
              <tbody
                key={clinicSummary.clinicName}
                className={cn('', {
                  'bg-gray-200': i % 2 === 1,
                  'bg-white': i % 2 === 0
                })}
              >
                <tr className="hover:bg-secondary hover:text-on-secondary">
                  <ClinicTopCell>{clinicSummary.clinicName}</ClinicTopCell>
                  <ClinicTopCell>{clinicSummary.totals.numVideos}</ClinicTopCell>
                  <ClinicTopCell>{clinicSummary.totals.numReviewed}</ClinicTopCell>
                  <ClinicTopCell>{clinicSummary.totals.numUsable}</ClinicTopCell>
                </tr>
                {clinicSummary.months && <ClinicSummaryRows clinicSummary={clinicSummary} />}
              </tbody>
            ))}
          </table>
        </Section>
      </QueryLoaderWrapper>
      <div className="mt-6 flex flex-row justify-start">
        <Button
          variant="primary"
          size="small"
          onClick={() => {
            navigate('/reports');
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};
