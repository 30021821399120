import React, { FunctionComponent, useEffect } from 'react';
import { useColors } from './brandingState';

interface BrandingProps {
  children: React.ReactNode;
}

// Branding is a wrapper that applies the branding styles via css variables to the document.
export const Branding: FunctionComponent<BrandingProps> = ({ children }) => {
  const colors = useColors();

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', colors.primary);
    document.documentElement.style.setProperty('--on-primary-color', colors.onPrimary);
    document.documentElement.style.setProperty('--secondary-color', colors.secondary);
    document.documentElement.style.setProperty('--on-secondary-color', colors.onSecondary);
    document.documentElement.style.setProperty('--destructive-color', colors.destructive);
    document.documentElement.style.setProperty('--on-destructive-color', colors.onDestructive);
    document.documentElement.style.setProperty('--accent-color', colors.accent);
    document.documentElement.style.setProperty('--on-accent-color', colors.onAccent);
    document.documentElement.style.setProperty('--background-color', colors.background);
    document.documentElement.style.setProperty('--on-background-color', colors.onBackground);
    document.documentElement.style.setProperty('--widget-color', colors.widget);
    document.documentElement.style.setProperty('--on-widget-color', colors.onWidget);
  }, [colors]);

  return <>{children}</>;
};
