import React, { FunctionComponent } from 'react';
import { ClinicSummary } from '../../../hooks/useClinicMonthSummaryReport';
import { DateMonthCell, DateSummaryCell } from '../TableComponents';

interface ClinicSummaryRowsProps {
  clinicSummary: ClinicSummary;
}

// ClinicSummaryRows is a component that displays the summary of all the videos that have been reviewed by clinic by
// month: the total number of videos, the total number of videos reviewed, and the total number of videos that are
// usable.
export const ClinicSummaryRows: FunctionComponent<ClinicSummaryRowsProps> = ({ clinicSummary }) => {
  return Object.entries(clinicSummary.months).map(([month, summary]) => (
    <tr key={`${clinicSummary.clinicName}-${month}`} className="hover:bg-secondary hover:text-on-secondary">
      <DateMonthCell>{month}</DateMonthCell>
      <DateSummaryCell>{summary.numVideos}</DateSummaryCell>
      <DateSummaryCell>{summary.numReviewed}</DateSummaryCell>
      <DateSummaryCell>{summary.numUsable}</DateSummaryCell>
    </tr>
  ));
};
