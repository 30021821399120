import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Page } from '../../components/Page';
import { ReportMenu } from './ReportMenu';
import { ReviewedSummaryByClinic } from './ReportSummaryByClinic';

// Reports is a component that displays the Reports page.  It defaults to displaying the ReportMenu component.
export const Reports: FunctionComponent = () => {
  return (
    <Page title="Reports" wrapWithSection={false}>
      <Routes>
        <Route path="*" element={<ReportMenu />} />
        <Route path="reviewed-summary-by-clinic" element={<ReviewedSummaryByClinic />} />
      </Routes>
    </Page>
  );
};
