import React, { FunctionComponent } from 'react';
import { DebugObjectTableBody } from './DebugObjectTableBody';

interface DebugObjectDisplayProps {
  objectToDisplay: { [key: string]: any };
}

// DebugObjectDisplay is a component that can be used to display a nested object in a table format.  This is useful for
// debugging purposes.
export const DebugObjectDisplay: FunctionComponent<DebugObjectDisplayProps> = ({ objectToDisplay }) => {
  return (
    <table className="w-full table-auto border border-solid border-gray-400 bg-white text-sm">
      <DebugObjectTableBody objectToDisplay={objectToDisplay} isTopLevel />
    </table>
  );
};
