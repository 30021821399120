import React, { FunctionComponent } from 'react';
import { cn } from '@companion-professional/webutils';
import { DebugObjectValue } from './DebugObjectValue';

interface DebugTableRowProps {
  index: number;
  entry: [string, unknown];
}

// DebugTableRow displays a single row of a table that displays a nested object.
export const DebugTableRow: FunctionComponent<DebugTableRowProps> = ({ entry, index }) => {
  const [key] = entry;

  return (
    <tr>
      <td
        className={cn('px-2', {
          'bg-gray-100': index % 2 === 0,
          'bg-gray-200': index % 2 === 1
        })}
      >
        {key}
      </td>
      <td
        className={cn('px-2', {
          'bg-gray-100': index % 2 === 0,
          'bg-gray-200': index % 2 === 1
        })}
      >
        <DebugObjectValue entry={entry} />
      </td>
    </tr>
  );
};
